<template>
	<b-modal size="xl" ref="modalInventaire" @show="init_component">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('monte.stock_semence.inventaire') }}</h2>
		</template>
		<template slot="default">
			<div class="row">
				<div class="col-auto">
            		<label>{{ $t('monte.stallion') }}</label>
            	</div>
				<div class="col">
	                <e-select
	                    v-model="stallion_selected"
	                    id="stallion"
	                    track-by="horse_id"
	                    label="horse_nom"
	                    :placeholder="$t('monte.selectionnez_etalon')"
	                    :selectedLabel="$t('global.selected_label')"
	                    :options="stallions"
	                    :searchable="true"
	                    :allow-empty="false"
	                    :show-labels="false"
	                />
	            </div>
				<div class="col-auto">
					<button class="btn btn-secondary" @click="addStallion"><font-awesome-icon :icon="['far', 'plus']" /></button>
				</div>
			</div>
			<div class="row">
				<span class="btn btn-link" @click="setAllDate">{{ $t('monte.all_date_today') }}</span>
			</div>
			<div v-if="inventories.length > 0" class="row mt-4">
				<div class="col">
				</div>
				<div class="col">
					<label>{{ $t('monte.stock_semence.stock') }}</label>
				</div>
				<div class="col">
                    <label>{{ $t('monte.stock_semence.date') }}</label>
				</div>
				<div class="col-auto"></div>
			</div>
			<div v-for="(inventory, key) in inventories" class="row border-top" :key="inventory.stallion.horse_id">
				<div class="col align-self-end">
                    <div class="form-group">
                        <span>{{ inventory.stallion.horse_nom }}</span>
                    </div>
                </div>
                <div class="col mt-2">
                    <div class="form-group">
                       <b-input
							type="number"
							v-model="inventory.stock"
						>
						</b-input>
                    </div>
                </div>
                <div class="col mt-2">
                    <div class="input-group w-100">
                    	<div class="input-group-prepend">
                            <div class="btn input-group-text" @click="setDateToday(key)" v-b-tooltip.hover="$t('table.general.reset_to_today')">
                                <font-awesome-icon :icon="['fal', 'calendar']" />
                            </div>
                        </div>
                        <e-datepicker v-model="inventory.date"></e-datepicker>
                    </div>
                </div>
                <div class="col-auto mt-2">
                    <button class="btn btn-secondary" @click.prevent="removeStallion(key)"><font-awesome-icon :icon="['far', 'trash-alt']" /></button>
                </div>
                <div class="col-12 mb-4">
               		<textarea class="form-control" :placeholder="$t('formulaire.votre_commentaire')" v-model="inventory.commentaire"></textarea>
                </div>
			</div>
	    </template>
		<template slot="modal-footer" class="justify-content-center">
		  	<div class="text-center">
                <b-button v-if="inventories.length > 0" @click="checkForm" variant="primary" class="rounded-pill">
                    {{ $t('global.ajouter') }} 
                    <font-awesome-icon v-if="processing" :icon="['fal', 'sync']" :spin="processing"/>
                    <font-awesome-icon v-else :icon="['fal', 'plus-circle']" class="ml-1"/>
                </b-button>
                <b-button @click="cancelInventory" class="rounded-pill ml-1">
					{{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/> 
				</b-button>
            </div>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
    import Horse from "@/mixins/Horse.js"
    import StockSemence from "@/mixins/StockSemence.js"

	export default {
		name: 'ModalInventaire',
		mixins: [Horse, StockSemence],
		props: ['tiers_id', 'tier_stallions'],
		data () {
			return {
				stallions: [],
				stallion_selected: {},
				inventories: [],
				processing: false
			}
		},

		methods: {
			async init_component() {
				this.stallions = await this.getHorsesStallion(false)
				this.inventories = []

				this.preloadStallion()
			},

			openModal() {
				this.$refs.modalInventaire.show()
			},

			addStallion() {
				if(!this.stallion_selected.horse_id) {
					return false
				}

				this.inventories.push({
					stallion: this.stallion_selected,
					date: new Date(),
					stock: 0,
					commentaire: ""
				})

				const index = this.stallions.indexOf(this.stallion_selected)
				if (index > -1) {
					this.stallions.splice(index, 1);
				}

				this.stallion_selected = {}
			},

			async checkForm() {
				const inventories = this.inventories.map(i => {
					return {
						date: i.date,
						stock: i.stock,
						stallion: i.stallion.horse_id,
						commentaire: i.commentaire
					}
				})

				this.processing = true
				await this.saveInventory(inventories, this.tiers_id)
				this.processing = false
				this.cancelInventory()
				this.$emit("done", true)
			},

			cancelInventory() {
				this.$refs.modalInventaire.hide()
				this.stallions = []
				this.stallion_selected = {}
				this.inventories = []
			},

			preloadStallion() {
				this.tier_stallions.forEach(stallion => {
					this.inventories.push({
						stallion: stallion.horse,
						date: new Date(stallion.date),
						stock: stallion.stock,
						commentaire: ""
					})

					const horse = this.stallions.find(s => s.horse_id == stallion.horse.horse_id)

					const index = this.stallions.indexOf(horse)
					if (index > -1) {
						this.stallions.splice(index, 1);
					}
				})
			},

			removeStallion(key) {
				this.stallions.push(this.inventories[key].stallion.horse)
				this.inventories.splice(key, 1);
			},

			setDateToday(key) {
				this.inventories[key].date = new Date()
			},

			setAllDate() {
				for(let i in this.inventories) {
					this.setDateToday(i)
				}
			}
		},

		components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'), 
		}
	}

</script>